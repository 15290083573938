<template>
  <section class="self_sec">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-6">
                  <div class="login_signup_form py_60 mt_10">
                   
                     <h2 class="text_black font_size_36 mb_40">Sign Up</h2>
                     <form @submit.prevent="registerUser">
                        <div class="form-group mb_50">
                          
                           <input type="text" placeholder="First Name" class="form-control"  v-model="name">
                          <span v-if="error && error.name" class="error" style="color:red">{{error.name[0]}}</span>
                        </div>
                        <div class="form-group mb_50">
                           
                           <input type="text" placeholder="Last Name" class="form-control">
                          <span v-if="error && error.name" class="error" style="color:red">{{error.name[0]}}</span>
                        </div>
                        <div class="form-group mb_50">
                         
                           <input type="email" placeholder="Email" class=" form-control" v-model="email">
                           <span v-if="error && error.email" class="error" style="color:red">{{error.email[0]}}</span>
                        </div>
                        <div class="form-group mb_50">
                          
                           <input type="password" placeholder="Password" class=" form-control" v-model="password">
                          <span v-if="error && error.password" class="error" style="color:red">{{error.password[0]}}</span>
                        </div>
                        <!-- <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Confirm Password</label>
                           <input type="password" placeholder="Confirm Password" class="mb_50 form-control" v-model="passwordConfirm">
                           <span v-if="error && error.password_confirmation" class="error" style="color:red">{{error.password_confirmation[0]}}</span>
                        </div> -->
                        <div class="form-group mb_50">
                          
                             <v-select v-model="selected" class="mb_50" :options="['Select Timezone','1']" placeholder="Select Timezone" />

                        </div>
                        <div class="form-group mb_50">
                          
                            <v-select v-model="selected" class="mb_50" :options="['Select Language','1']" placeholder="Select Language"/>
                        </div>
                        <div class="form-group mb_50">
                          
                           <v-select v-model="selected" class="" :options="['Select Region','1']" placeholder="Select Region"/>
                        </div>
                        <!-- <div class="form-group mb_50">
                           <label class="font_size_24 mb_20 font_bold">Client Type</label>                          
                           <v-select v-model="selected" :options="['Select Client Type','1']" placeholder="Select Client Type"/>
                        </div> -->
                        <div class="form-group mb_50 disclaimer">
                           <p class="font_size_16 mb_20 "> <strong>Disclaimer:</strong>  The instruction and advice presented are in no way a substitute for medical counseling. The creators, producers and distributors of this program disclaim any liability in connection with the exercises and advice herein</p>         
                          <div class="form_check_box d-flex align-items-lg-center">
                            <input
                              class="form-check-input ms-0"
                              type="checkbox"
                              value=""
                              aria-label="..."
                              v-model="remember_me" id="flexCheckDefault"
                            />
                            <label class="font_size_24" for="flexCheckDefault">I have read and understand the Disclaimer *</label>
                          </div>
                          <!-- <p class="font_size_16 my_20">Once submitted, a <a href="#" class="text_balticsea temporary font_bold">temporary</a> password will be sent to your email.</p>          -->
                        </div>
                        <div class="mt_68 d-flex justify-content-between align-items-center btn_link_box">
                           <div class="">
                             <button class="btn btn_primary">Register</button>
                           </div>
                           <div class="accout_box">
                              <p class="m-0">
                                 Already have an account? 
                                 <router-link :to="{ name: 'login' }"><span>Login</span></router-link>
                              </p>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div class="col-md-6 p-0">
                  <div class="self_img">
                     <img src="../../assets/home/images/schedule_img.png" alt="img">
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script>
import "vue-select/dist/vue-select.css"
export default {
  name: "register",
};
</script>
